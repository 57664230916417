import { useContext, useEffect, useReducer } from "react";
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from "@mui/material";

import { AddCircle, Edit, Facebook, Instagram, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { AddRedSocial, GetRedesSociales, UpdateRedSocial } from "../../../controllers/company/CompanyProfileController";
import UserContext from "../../../navigation/context/userContext";



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    confSocials: [],
    redesSociales:[],

    uuid:'',
    idRedSocial: '',    errorIdRedSocial: false,
    enlace:'',          errorEnlace: false,
    username:'',        errorUsername: false,
    estatus:'',         errorEstatus: false,

    operacion:'agregar',
    
    isLoading : false,

    open: false,

    openAlert: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function Socials()
{
    const {userData,setUserData} = useContext(UserContext);
    const [state,setState] = useReducer(reducer,initialState);

    const {
        confSocials, redesSociales,
        uuid,
        idRedSocial, errorIdRedSocial, 
        enlace, errorEnlace, 
        username, errorUsername, 
        estatus, errorEstatus,

        operacion,

        isLoading,
        open,

        openAlert,
        title,
        message,
        success

    } = state;

    useEffect(()=>{
        handleGetRedesSociales();
    },[])

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleGetRedesSociales = async () => {
        let response = await GetRedesSociales();
        if(response.success === true)
        {
            setState({field:'confSocials',value:response.data.confSocials});
            setState({field:'redesSociales',value:response.data.redesSociales});

        }
    }

    const cancel = () => {
        setState({field:'enlace',value:''});
        setState({field:'username',value:''});
        setState({field:'idRedSocial',value:''});
        setState({field:'estatus',value:''});

        setState({field:'errorEnlace',value:false});
        setState({field:'errorUsername',value:false});
        setState({field:'errorIdRedSocial',value:false});
        setState({field:'errorEstatus',value:false});
    }

    const handleOpenAdd = () => {
        setState({field:'operacion',value:'agregar'});

        cancel();

        setState({field:'open',value:true});
    }

    const handleOpenUpdate = (item) => {
        setState({field:'operacion',value:'actualizar'});

        setState({field:'uuid',value:item.uuid});
        setState({field:'enlace',value:item.enlace});
        setState({field:'username',value:item.username});
        setState({field:'idRedSocial',value:item.idRedSocial});
        setState({field:'estatus',value:item.estatus});

        setState({field:'open',value:true});
    }

    const handleClose = () => {
        cancel();
        setState({field:'open',value:false})
    }

    const formVerification = () => {
        var ban = 0;

        if(idRedSocial === '')
        {
            setState({field:'errorIdRedSocial',value:true});
            ban = 1;
        }

        if(enlace === '')
        {
            setState({field:'errorEnlace',value:true});
            ban = 1;
        }
        
        if(username === '')
        {
            setState({field:'errorUsername',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }
        
        return ban;
    }
    

    const handleAddSubmit = async () => {
        
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});

            if(operacion === 'agregar')
            {
                let response = await AddRedSocial(idRedSocial,username,enlace,estatus);
                if(response.success === true)
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:true});
                    setState({field:'openAlert',value:true});
                }
                else
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:true});
                }
            }
            else
            {
                let response = await UpdateRedSocial(uuid,idRedSocial,username,enlace,estatus);
                if(response.success === true)
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:true});
                    setState({field:'openAlert',value:true});
                }
                else
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:true});
                }
            }
            setState({field:'isLoading',value:false});
            handleClose();
            handleGetRedesSociales()
        }
    }

    return(
        <Paper>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Redes Sociales</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpenAdd()}>
                    <AddCircle/>
                </IconButton>
            </div>

            <List>
                {
                    redesSociales.length !== 0 &&
                    redesSociales.map((item)=>
                        <ListItem
                            key={item.id}
                            secondaryAction={
                                <IconButton edge='end' onClick={()=>handleOpenUpdate(item)}>
                                    <Edit/>
                                </IconButton>
                            }
                        >
                            <ListItemIcon>
                                {
                                    item.idRedSocial === 1
                                    ?<Instagram/>
                                    :item.idRedSocial === 3
                                    ?<Facebook/>
                                    :item.idRedSocial === 2
                                    ?<YouTube/>
                                    :item.idRedSocial === 4
                                    ?<Twitter/>
                                    :item.idRedSocial === 5
                                    ?<WhatsApp/>
                                    :<Language/>
                                }
                            </ListItemIcon>
                            <ListItemText id={'labelId'+item.id} primary={<Typography noWrap={true}>{item.url}</Typography>} secondary={item.username} />

                        </ListItem>
                    )
                }
            </List>


            <Dialog
                open={open}
            >
                <DialogTitle>
                    {operacion === 'agregar' ? 'Agregar' : 'Actualizar'} Red Social
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="idRedSocial"
                            name='idRedSocial'
                            label="Red Social"
                            variant="outlined"
                            value={idRedSocial}
                            onChange = { onChange }
                            onBlur={ () => (idRedSocial === '' ? setState({field:'errorIdRedSocial',value:true}) : setState({field:'errorIdRedSocial',value:false}) ) }
                            error = {errorIdRedSocial}
                            helperText='Debe seleccionar una red social'
                        >
                            <MenuItem value=''>Seleccione una plataforma</MenuItem>
                            {
                                confSocials.length !== 0 
                                ?
                                confSocials.map((item)=>
                                    <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                )
                                :null
                            }
                            
                        </TextField>
                    </FormControl>
                    <TextField
                        margin="normal"
                        id="enlace"
                        name='enlace'
                        label="Enlace"
                        fullWidth
                        variant="outlined"
                        value={enlace}
                        onChange={ onChange }
                        onBlur={ () => (enlace === '' ? setState({field:'errorEnlace',value:true}) : setState({field:'errorEnlace',value:false}) ) }
                        error = {errorEnlace}
                        helperText={errorEnlace && 'Debe ingresar la url del enlace'}
                    />
                    <TextField
                        margin="normal"
                        id="username"
                        name='username'
                        label="Nombre de Usuario"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={ onChange }
                        onBlur={ () => (username === '' ? setState({field:'errorUsername',value:true}) : setState({field:'errorUsername',value:false}) ) }
                        error = {errorUsername}
                        helperText={errorUsername && 'Debe ingresar el username'}
                    />
                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="estatus"
                            name='estatus'
                            label="Estatus"
                            variant="outlined"
                            value={estatus}
                            onChange = { onChange }
                            onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                            error = {errorEstatus}
                            helperText={errorEstatus && 'Debe ingresar seleccionar el estatus'}
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={()=>handleClose()}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleAddSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:false});
                }}
                autoHideDuration={6000}
            >
                <Alert severity={success === true ? 'success' : 'error'}>
                    <AlertTitle>
                        {
                            success === true
                            ? 'Operacion Exitosa!'
                            : 'Error!'
                        }
                    </AlertTitle>
                    {message}
                </Alert>
            </Snackbar>
        </Paper>
    )
}