import { Switch } from "react-router-dom";
import AdminLayout from "../layouts/DashboardAdmin/adminLayout";


import AppRoute from "./AppRoute";
import Home from "../views/admin";
import TourismCategories from "../views/admin/tourism/categories";
import ChangePassword from "../views/auth/changePassword";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom";
import Branch from "../views/admin/branch";
import Orders from "../views/admin/orders";
import OrdersCreate from "../views/admin/orders/create";
import OrdersUpdate from "../views/admin/orders/update";
import Expenses from "../views/admin/expenses";
import ExpensesCreate from "../views/admin/expenses/create";
import Products from "../views/admin/products";
import ProductsCreate from "../views/admin/products/create";
import ProductsUpdate from "../views/admin/products/update";
import Categories from "../views/admin/categories";
import CategoriesCreate from "../views/admin/categories/create";
import CategoriesUpdate from "../views/admin/categories/update";
import ProductDetails from "../views/admin/products/details";
import Customers from "../views/admin/customers";
import CustomerCreate from "../views/admin/customers/create";
import DetailPDF from "../views/admin/products/detailPDF";



const branch = [
    {
        path:'/branch',
        layout:AdminLayout,
        routes:[
            {
                path:"/branch/:branch_name",
                component: Branch
            },
            {
                path:"/branch/:branch_name/categories",
                component: Branch
            },
            {
                path:"/branch/:branch_name/customers",
                component: Branch
            },
            {
                path:"/branch/:branch_name/orders",
                component: Branch
            },
        ]
    }
];



export default function AdminNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AdminLayout} component={Home} />

            {
                branch.map((item,index)=>
                    item.routes.map(route=>
                        <AppRoute
                            exact
                            path={route.path}
                            layout={item.layout}
                            component={route.component}
                        />
                    )
                )
            }


            <AppRoute exact path='/orders' layout={AdminLayout} component={Home} />
            <AppRoute exact path='/orders/:type' layout={AdminLayout} component={Orders} />
            <AppRoute exact path='/orders/:type/create' layout={AdminLayout} component={OrdersCreate} />
            <AppRoute exact path='/orders/:type/update/:id' layout={AdminLayout} component={OrdersUpdate} />

            <AppRoute exact path='/entities/:type' layout={AdminLayout} component={Customers} />
            <AppRoute exact path='/entities/:type/create' layout={AdminLayout} component={CustomerCreate} />

            <AppRoute exact path='/expenses' layout={AdminLayout} component={Expenses} />
            <AppRoute exact path='/expenses/create' layout={AdminLayout} component={ExpensesCreate} />

            <AppRoute exact path='/products' layout={AdminLayout} component={Products} />
            <AppRoute exact path='/products/create' layout={AdminLayout} component={ProductsCreate} />
            <AppRoute exact path='/products/update/:id' layout={AdminLayout} component={ProductsUpdate} />
            <AppRoute exact path='/products/details/:id' layout={AdminLayout} component={ProductDetails} />
            <AppRoute exact path='/products/details/:id/informe' layout={AdminLayout} component={DetailPDF} />
            

            <AppRoute exact path='/categories' layout={AdminLayout} component={Categories} />
            <AppRoute exact path='/categories/create' layout={AdminLayout} component={CategoriesCreate} />
            <AppRoute exact path='/categories/update/:id' layout={AdminLayout} component={CategoriesUpdate} />


            {/**SETTINGS */}
            <AppRoute exact path='/settings' layout={AdminLayout} component={Home} />
            <AppRoute exact path='/settings/tourism/categories' layout={AdminLayout} component={TourismCategories} />

            {/**SECURITY */}
            <AppRoute exact path='/security/changepassword' layout={AdminLayout} component={ChangePassword} />

            {/**Para las rutas no encontradas 
            <Route path="*">
                <Redirect path="/"/>
            </Route>*/}
        </Switch>
    )
}