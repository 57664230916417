import { useContext, useReducer } from "react";
import { Box, Container, Grid, Paper, TextField, Typography, InputAdornment, Checkbox, Link, Button, Divider, MenuItem } from "@mui/material";


import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { personSignUp } from "../../controllers/AuthController";
import AuthContext from "../../navigation/context/authContext";

import login_photo from '../../assets/images/website/loginui.png';
import logo from '../../assets/images/logos/black.png';
import ResponseComponent from "../../components/responseComponent";
import QUDProgress from "../../components/progress";

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    name: '',               errorName:false,
    lastName: '',           errorLastName:false,
    email : '',             errorEmail : false,
    documentType:'',
    document:'',            errorDocument: false,
    prefix:'58',
    phoneNumber:'',         errorPhoneNumber: false,
    //username:'',            errorUsername: false,
    password : '',          errorPassword : false,
    passwordConfirm : '',   errorPasswordConfirm : false,
    agree:false,            errorAgree: false,
    
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,

    isOpen : false,
    result : false,
    message : '',
    title : '',
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  



export default function PersonSignUp(props)
{
    const { signIn } = useContext(AuthContext);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        name, errorName, lastName, errorLastName,
        documentType, document, errorDocument,
        prefix, phoneNumber, errorPhoneNumber,
        email, errorEmail,
        //username, errorUsername,
        password, errorPassword,
        passwordConfirm, errorPasswordConfirm,
        agree, errorAgree,

        isLoading,
        errorSignIn, errorSignInMsg,
        redirectToVerify,
        registrado,

        isOpen,
        title, message, success

    } = state;

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }


    const formVerification = () => {
        var ban = 0;

        if(name === '')
        {
            setState({field:'errorName',value:true});
            ban = 1;
        }

        if(lastName === '')
        {
            setState({field:'errorLastName',value:true});
            ban = 1;
        }

        if(email === '' /*|| emailReg.test(email) === false*/)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        /** 
        if(username === '')
        {
            setState({field:'errorUsername',value:true});
            ban = 1;
        }*/
        
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(agree !== true)
        {
            setState({field:'errorAgree',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;
            
        setState({field:'isLoading',value:true})
        let response = await personSignUp(name,lastName,email,password);
        if(response.success === true)
        {
            setState({field:'title',value:'Operacion Exitosa!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:true});
            setState({field:'isOpen',value:true});
        }
        else if(response.success === false)
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'message',value:response.message});
            setState({field:'success',value:false});
            setState({field:'isOpen',value:true});
        }
        else
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'msg',value:'En estos momentos su solicitidu no puede ser procesada!'});
            setState({field:'isOpen',value:true});
        }
        
        setState({field:'isLoading',value:false})
    }


    const handleclose = async (redireciconar = true) => {
        if(title === 'Operacion Exitosa!')
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});

            if(props.stay === true)
                signIn({email,password});
            else
                redirect('/auth/signIn');
        }
        else
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});
        }
    }

    return(
        <Container maxWidth='xl'>
            <Grid container spacing={3}>
                <Grid item lg={5} xl={5} sx={{display:{xs:'none',sm:'none',md:'none',lg:'block',xl:'block'}}} >
                    <Box sx={{width:'100%'}}>
                        <img
                            style={{width:'100%',borderRadius:20}}
                            src={login_photo}
                            alt=""
                        />
                    </Box>
                </Grid>
                <Grid item lg={7} xl={7} display={'flex'} alignItems={'center'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item lg={7} xl={7} display={'flex'} justifyContent={'center'}>
                            <Box sx={{width:85,height:85,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img
                                    style={{width:85,height:85}}
                                    src={logo}
                                    alt=""
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Typography variant="h4" textAlign={'center'}>Registro de cuenta personal</Typography>
                        </Grid>
                        <Grid item lg={8} xl={8}>
                            <Grid container spacing={3} mt={3}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        name="name"
                                        label="Nombre"
                                        value={name}
                                        onChange={(e)=>setState({field:'name',value:e.target.value})}
                                        onBlur={ () => (setState({field:'errorName',value:name===''?true:false})) }
                                        error = {errorName}
                                        helperText={errorName && 'Ingrese el nombre'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="lastName"
                                        name="lastName"
                                        label="Apellido"
                                        value={lastName}
                                        onChange={(e)=>setState({field:'lastName',value:e.target.value})}
                                        onBlur={ () => (setState({field:'errorLastName',value:lastName===''?true:false})) }
                                        error = {errorLastName}
                                        helperText={errorLastName && 'Ingrese el apellido'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Correo electrónico"
                                        value={email}
                                        onChange={(e)=>setState({field:'email',value:e.target.value})}
                                        onBlur={ () => (setState({field:'errorEmail',value:email===''?true:false})) }
                                        error = {errorEmail}
                                        helperText={errorEmail && 'Ingrese el número de teléfono'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name='password'
                                        label="Contraseña"
                                        placeholder='Contraseña'
                                        variant="outlined"
                                        type="password"
                                        value={password}
                                        onChange = { onChange }
                                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                        error = {errorPassword}
                                        helperText={errorPassword && 'Ingrese la contraseña'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="passwordConfirm"
                                        name='passwordConfirm'
                                        label="Confirmar contraseña"
                                        placeholder='Confirmar contraseña'
                                        variant="outlined"
                                        type="password"
                                        value={passwordConfirm}
                                        onChange = { onChange }
                                        onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                        error = {errorPasswordConfirm}
                                        helperText = {(password !== '' && passwordConfirm === '') && 'Ingrese la Confirmación de Contraseña' || passwordConfirm !== password && 'La contraseña no coincide'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                    style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}
                                >
                                    <Checkbox
                                        checked={agree} 
                                        onChange={()=>{ 
                                            setState({field:'agree',value:!agree})} 
                                        }
                                        name="agree"
                                        id='agree' 
                                        onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                                    />
                                
                                    <Typography>
                                        Declaro que he leído y acepto las&nbsp;
                                        <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/privacy' target='_blank'>
                                            Políticas de Privacidad
                                        </Link> 
                                        &nbsp;así como los&nbsp;
                                        <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/termsandconditions' target='_blank'>
                                            Términos y Condiciones
                                        </Link> 
                                        &nbsp;de uso de QUD. 
                                    </Typography>
                                </Grid>
                                {
                                    errorAgree === true
                                    ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de QUD</Typography>
                                    :null
                                }
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        onClick={()=>handleSubmit()}
                                    >
                                        <Typography fontWeight={'bold'}>
                                            Registrarme
                                        </Typography>
                                        
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ResponseComponent
                show={isOpen}
                title={title}
                success={success}
                message={message}
                handleClose={()=>handleclose()}
                actionText="Aceptar"
                onClick={()=>handleclose()}
                action='Finalizar'
            />
            <QUDProgress open={isLoading}/>
        </Container>
        
    )
}

/**
 * <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                pb:5
            }}
        >
        <Container maxWidth='sm'>
            <SectionTitle title='Registro de Persona'/>
            
            <Paper elevation={6} sx={{pt:5,pb:5}}>
                <Box
                    component='form'
                    
                    spacing={2}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            
                            <TextField
                                fullWidth
                                id="name"
                                label="Nombre"
                                placeholder='Nombre'
                                name='name'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                value={name}
                                onChange = { onChange }
                                onBlur={ () => (name === '' ? setState({field:'errorName',value:true}) : setState({field:'errorName',value:false}) ) }
                                error = {errorName}
                                helperText={errorName && 'Ingrese tu nombre'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            
                            <TextField
                                fullWidth
                                id="lastName"
                                label="Apellido"
                                placeholder='Apellido'
                                name='lastName'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                value={lastName}
                                onChange = { onChange }
                                onBlur={ () => (lastName === '' ? setState({field:'errorLastName',value:true}) : setState({field:'errorLastName',value:false}) ) }
                                error = {errorLastName}
                                helperText={errorLastName && 'Ingresa tu apellido'}
                            />
                        </Grid>

                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Correo"
                                placeholder='Correo'
                                name='email'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                value={email}
                                onChange = { onChange }
                                onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                error = {errorEmail}
                                helperText={errorEmail && 'Ingrese el correo electronico'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="password"
                                name='password'
                                label="Contraseña"
                                placeholder='Contraseña'
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                type="password"
                                value={password}
                                onChange = { onChange }
                                onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                error = {errorPassword}
                                helperText={errorPassword && 'Ingrese la contraseña'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                id="passwordConfirm"
                                name='passwordConfirm'
                                label="Confirmar Contrasena"
                                placeholder='Confirmar Contrasena'
                                variant="outlined"
                                    InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                type="password"
                                value={passwordConfirm}
                                onChange = { onChange }
                                onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                error = {errorPasswordConfirm}
                                helperText = {(password !== '' && passwordConfirm === '') && 'Ingrese la Confirmación de Contraseña' || passwordConfirm !== password && 'La contraseña no coincide'}
                            />
                        </Grid>

                    </Grid>
                    

                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>
                        <Checkbox
                                checked={agree} 
                                onChange={()=>{ 
                                    setState({field:'agree',value:!agree})} 
                                }
                                name="agree"
                                id='agree' 
                                onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                            />
                        <Typography>
                            Declaro que he leído y acepto las&nbsp;
                            <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/privacy' target='_blank'>
                                Políticas de Privacidad
                            </Link> 
                            &nbsp;así como los&nbsp;
                            <Link sx={{color:'link.main',textDecoration:'none'}} component='a' href='https://qud.life/termsandconditions' target='_blank'>
                                Términos y Condiciones
                            </Link> 
                            &nbsp;de uso de QUD. 
                        </Typography>
                    </div>
                    {
                        errorAgree === true
                        ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de QUD</Typography>
                        :null
                    }
                    <br/>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={isLoading}
                        onClick={()=>handleSubmit()}
                    >
                        <Typography fontWeight={'bold'}>
                            Registrarme
                        </Typography>
                        
                    </LoadingButton>
                        
                </Box>
            </Paper>

            <ModalDialog
                open={isOpen}
                title={title}
                msg={msg}
                handleClose={()=>handleclose()}
                action='Aceptar'
                handleAction={()=>handleclose()}
            />
        </Container>
        </Box>
 */