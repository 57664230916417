import { useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserContext from "../../../navigation/context/userContext";
import DetailsDialog from "../../../components/DetailsDialog";
import OrderDetail from "./OrderDetail";

export default function OrdersDataTable(props)
{
    const { userData } = useContext(UserContext);
    const { type } = useParams();
    const [orders,setOrders] = useState(props.orders);
    const [selectedOrder,setSelectedOrder] = useState(null);

    const [isOpen,setIsOpen] = useState(false);

    const memoizedOrders = useMemo(() => props.orders, [props.orders]);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    const handleSelectedOrder = (id) => {
        setSelectedOrder(id);
        setIsOpen(true);
    }

    /*useEffect(()=>{
        if(selectedOrder !== null)
        setIsOpen(true)
    },[selectedOrder]);*/

    const handleType = () => {
        if(type === 'inbound')
            return 'Entrada';
        else
            return 'Salida';
    }

    let columns;

    if(userData.user.type === 1)
        columns = [
            {
                name:'id',
                label:'ID',
                options:{
                    filter:false,
                    searchable:false
                }
            },
            {
                name:'date',
                label:<Typography>Fecha</Typography>,
                options:{
                    filter: false
                }
            },
            {
                name:'date_production',
                label:<Typography>Fecha Produccion</Typography>,
                options:{
                    filter: false
                }
            },
            {
                name:'created_by',
                label:<Typography>Creado por</Typography>,
                options:{
                    filter: true
                }
            },
            {
                name:'updated_by',
                label:<Typography>Actualizado por</Typography>,
                options:{
                    filter: true
                }
            },
            {
                name:'number',
                label:<Typography>Número</Typography>,
                options:{
                    filter: false,
                }
            },
            {
                name:'customer',
                label:<Typography>Cliente</Typography>,
                options:{
                    filter: false,
                }
            },
            {
                name:'currency',
                label:<Typography>Moneda</Typography>,
            },
            {
                name : 'ff_amount',
                label : 'Monto',
                options:{
                    customHeadRender:()=>{
                        return(
                            <TableCell align="right">
                                <Typography>Monto</Typography>
                            </TableCell>
                        )
                    },
                    customBodyRenderLite : (dataIndex) => {
                        //let id = subCategorias[dataIndex].idSubCategoria;
                        return(
                            <Typography textAlign={'right'}>
                                {memoizedOrders[dataIndex].ff_amount}
                            </Typography>
                        );
                    },
                    filter:false,
                    //filterType:'dropdown',
                    //display:false,
                    searchable:false,
                    headerStyle: {
                        textAlign: 'right' // Align header text to the right
                    }
                }
            },
            {
                name : '',
                label : <Typography>Acciones</Typography>,
                options : 
                {
                    customHeadRender:()=>{
                        return(
                            <TableCell align="right">
                                <Typography>Acciones</Typography>
                            </TableCell>
                        )
                    },
                    customBodyRenderLite : (dataIndex) => {
                        //let id = subCategorias[dataIndex].idSubCategoria;
                        return(
                            <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                                {/*<Tooltip title='Detalles'>
                                    <IconButton onClick={()=>redirect(`/orders/${type}/update/${memoizedOrders[dataIndex].id}`)}>
                                        <FontAwesomeIcon icon={faFileInvoice}/>
                                    </IconButton>
                                    
                                </Tooltip>*/}

                                <Tooltip title='Detalles New'>
                                    <IconButton onClick={()=>handleSelectedOrder(memoizedOrders[dataIndex].id)}>
                                        <FontAwesomeIcon icon={faFileInvoice}/>
                                    </IconButton>
                                </Tooltip>

                                
                            </Box>
                        );
                    },

                    filter:false,
                    print:false
                }
            }
        ];
    else
    columns = [
        {
            name:'id',
            label:'ID',
            options:{
                filter:false,
                searchable:false
            }
        },
        {
            name:'date',
            label:<Typography>Fecha</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'number',
            label:<Typography>Número</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name:'customer',
            label:<Typography>Cliente</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Detalles'>
                                <IconButton onClick={()=>redirect(`/orders/${type}/update/${orders[dataIndex].id}`)}>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Detalles New'>
                                <IconButton onClick={()=>setSelectedOrder(memoizedOrders[dataIndex].id)}>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <>
            <MUIDataTable
                title={<Typography>Listado de Ordenes de {props.type}</Typography>}
                data={memoizedOrders}
                columns={columns}
                options={options}
            />
            <DetailsDialog
                open={isOpen}
                title={`Orden de ${handleType()} #${selectedOrder}`}
                subtitle={'(Detalles)'}
                handleClose={()=>setIsOpen(false)}
            >
                <OrderDetail type={type} id={selectedOrder}/>
            </DetailsDialog>
        </>
    )
}