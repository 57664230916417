import React, { useReducer } from 'react';
import { Breadcrumbs, Button, Container, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import ModalDialog from '../../components/modalDialog';
import { LoadingButton } from '@mui/lab';
import { changePasswordLogin, changePasswordWithToken } from '../../controllers/AuthController';



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    currentPassword: '',    errorCurrentPassword: false,
    password: '',           errorPassword : false,
    passwordConfirm: '',    errorPasswordConfirm : false,
    
    isLoading : false,
    
    showModal : false,
    modalTitle: '',
    modalMsg: ''
}

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}



export default function ChangePassword()
{
    const [state,setState] = useReducer(reducer,initialState);
    const 
    {
        currentPassword, errorCurrentPassword,
        password, errorPassword,
        passwordConfirm, errorPasswordConfirm,
        isLoading,
        showModal, modalTitle, modalMsg
    } = state;

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    const handleClose = () => {
        setState({field:'showModal',value:false});
        setState({field:'currentPassword',value:''});
        setState({field:'password',value:''});
        setState({field:'passwordConfirm',value:''});
    }

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const formVerification = () => {
        var ban = 0;
        
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () =>
    {
        if(formVerification() === 0)
        {
            let response = await changePasswordLogin(password,passwordConfirm);
            if(response.success === true)
            {
                setState({field:'modalTitle',value:'Success!'});
                setState({field:'modalMsg',value:response.message});
                setState({field:'showModal',value:true});
            }
            else
            {
                setState({field:'modalTitle',value:'Error!'});
                setState({field:'modalMsg',value:response.message});
                setState({field:'showModal',value:true});
            }
        }
    }

    const handleResult = () => {
        
        if(modalTitle === 'Success!')
        {
            history.push('/');
        }
        else
        {
            handleClose();
        }

    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Password
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginTop:20,marginBottom:20}}>
                Change Password
            </Typography>
            <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Box component='form'
                            sx={{
                                '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%' },
                            }}
                        >
                            <Grid container>
                                
                                <TextField
                                    fullWidth
                                    required
                                    variant='outlined'
                                    id="password"
                                    name='password'
                                    value={password}
                                    label="New password"
                                    onChange = { onChange }
                                    onBlur={ () => (password === '' ? setState({field:'errorCurrentPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                    error = {errorPassword}
                                    helperText={errorPassword && 'Ingrese la nueva contraseña'}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    variant='outlined'
                                    id="passwordConfirm"
                                    name='passwordConfirm'
                                    value={passwordConfirm}
                                    label="Confirm new password"
                                    onChange = { onChange }
                                    onBlur={ () => (password === '' ? setState({field:'errorCurrentPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                    error = {errorPassword}
                                    helperText={errorPasswordConfirm && 'La nueva contraseña no coincide'}
                                />

                                <LoadingButton
                                    loading={isLoading}
                                    color='primary'
                                    variant='contained'
                                    fullWidth sx={{mt:2}}
                                    onClick={()=>handleSubmit()}
                                >
                                    Change Password
                                </LoadingButton>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <ModalDialog
                open={showModal}
                title={modalTitle}
                msg={modalMsg}
                action='Aceptar'
                handleAction={()=>handleResult()}
                handleClose={()=>handleClose()}

            />
        </Container>
    )
}