import { useRef } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, render } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Grid,Button, Container } from '@mui/material';

export default function DetailPDF(props)
{
    const ref = useRef(null);

    const handleDownload = () => {
        console.log('HACIENDO CLIK');
        const pdfData = render(ref.current);
        pdfData.then(data => {
            saveAs(data.blob, 'my-document.pdf');
        });
    };

    const data = [
        { id:1, name: 'John Doe', age: 30, country:' Venezuela' },
      ];
      
    return(
        <Container>
            <Grid container spacing={3}>
            <PDFViewer style={{width:'100%',height:'80vh'}}>
                <Document style={{width:'100%'}}>
                    <Page size="A4" orientation='landscape' style={styles.page}>
                        <View style={styles.section}>
                            <Text style={{marginBottom:30}}>Detalles de {props.product}</Text>
                            <View style={styles.row}>
                                {
                                    props.statistics.length !== 0 &&
                                    props.statistics.map(item=>{
                                        if(item.type === 'inbound')
                                            return(
                                                <View>
                                                    <Text variant='h6'>Entrada</Text>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Operaciones: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.operaciones}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Producción: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.produccion} {item.unidad}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Recibido: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.recibido} {item.unidad}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Monto: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.facturado} $
                                                        </Text>
                                                    </View>
                                                </View>
                                            )
                                        else return null
                                    })
                                }
                                {
                                    props.statistics.length !== 0 &&
                                    props.statistics.map(item=>{
                                        if(item.type === 'outbound')
                                            return(
                                                <View style={{marginLeft:40}}>
                                                    <Text variant='h6'>Salida</Text>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Operaciones: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.operaciones}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Vendido: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.recibido} {item.unidad}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.row}>
                                                        <Text textAlign={'center'}>- Monto: </Text>
                                                        <Text style={{fontWeight:'bold'}}>
                                                            {item.facturado} $
                                                        </Text>
                                                    </View>
                                                </View>
                                            )
                                        else return null
                                    })
                                }
                            </View>

                            <View style={{marginTop:50}}>
                                <Text>Detalles</Text>
                                
                                <View style={styles.table}>
                                    {/* Header */}
                                    <View style={[styles.tableRow, styles.header]}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Fecha</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Matadero</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>FF</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Tipo</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Detalles</Text>
                                        </View>
                                    </View>

                                    {/* Data Rows */}
                                    {
                                        props.details.length > 0 &&
                                        props.details.map((item, index) => (
                                            <View style={styles.tableRow} key={index}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{item.date}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{item.matadero}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{item.ff}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{item.type}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>#{item.order_number} {item.notes}</Text>
                                                </View>
                                            </View>
                                        ))
                                    }
                                </View>


                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
            </Grid>
        </Container>
    )
}



const styles = StyleSheet.create({
    page: {
        display:'flex',
        flexDirection: 'row',
        width:'100%',
        //backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    row:{
        display:'flex',
        flexDirection:'row'
    },

    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        margin: 10,
      },
      tableRow: {
        flexDirection: 'row',
      },
      tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        padding: 5,
      },
      tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10,
      },
      header: {
        fontWeight: 'bold',
        backgroundColor: '#EEE',
      },
});