import { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { get_branches } from "../../../controllers/admin/BranchController";
import BranchContext from "../../../navigation/context/branchContext";
import { getAllproducts } from "../../../controllers/admin/ProductsController";
import ProductsDataTable from "../products/dataTable";
import UserContext from "../../../navigation/context/userContext";
import Arrimadores from "./arrimadores";

export default function Branch()
{
    const {userData} = useContext(UserContext);
    const [products,setProducts] = useState([]);
    const {branch_id} = useParams();

    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const history = useHistory();

    const redirect = (route) => {
        //history.push(`/branch/${selectedBranch.name}/${route}`);
        history.push(route);
    }

    useEffect(()=>{
        handleGetProducts()
    },[]);


    useEffect(()=>{
        handleGetProducts();
    },[]);

    const handleGetProducts = async () => {
        let response = await getAllproducts(selectedBranch.id);
        if(response.success === true)
        {
            setProducts(response.data.products); 
        }
    }

    const options = [
        {name:'Categorias',route:'categories'},
        {name:'',route:''},
    ];

    return(
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight={'bold'}>{selectedBranch.name}</Typography>
                </Grid>
                
                    <Arrimadores/>
                
                {
                    userData.user.type === 1 &&
                    <>
                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                            <Button variant="outlined" fullWidth onClick={()=>redirect('/categories')}>
                                <Typography variant="h6">
                                    Categorias
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} onClick={()=>redirect('/products')}>
                            <Button variant="outlined" fullWidth>
                                <Typography variant="h6">
                                    Productos
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} onClick={()=>redirect('/entities/customer')}>
                            <Button variant="outlined" fullWidth>
                                <Typography variant="h6">
                                    Clientes
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} onClick={()=>redirect('/entities/supplier')}>
                            <Button variant="outlined" fullWidth>
                                <Typography variant="h6">
                                    Proveedores
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} onClick={()=>redirect('/entities/employee')}>
                            <Button variant="outlined" fullWidth>
                                <Typography variant="h6">
                                    Empleados
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} onClick={()=>redirect('/orders/inbound')}>
                    <Button variant="outlined" fullWidth>
                        <Typography variant="h6">
                            Ordenes de entrada
                        </Typography>
                    </Button>
                </Grid>

                {
                    (userData.user.type === 1 || userData.user.type === 3) &&
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} onClick={()=>redirect('/orders/outbound')}>
                        <Button variant="outlined" fullWidth>
                            <Typography variant="h6">
                                Ordenes de salida
                            </Typography>
                        </Button>
                    </Grid>
                }

                {
                    userData.user.type === 1 &&
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} onClick={()=>redirect('/expenses')}>
                        <Button variant="outlined" fullWidth>
                            <Typography variant="h6">
                                Gastos
                            </Typography>
                        </Button>
                    </Grid>
                }
                

                {
                    (products.length !== 0) &&
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                            <Typography variant="h6" fontWeight={'bold'}>Productos</Typography>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <ProductsDataTable products={products}/>
                        </Grid>
                    </>
                }
            </Grid>
        </Container>
    )
}