import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, TextField, Button, IconButton, CircularProgress } from "@mui/material";
import {LoadingButton} from '@mui/lab';

import ReactInputVerificationCode from 'react-input-verification-code';


import { changePassword, requestCodeToResetPassword, verifyRequestedCode } from "../../controllers/AuthController";
import ModalDialog from '../../components/modalDialog';

export default function ForgotPassword()
{
    const [isLoading,setIsLoading] = useState(false);
    const [step, setStep] = useState(0);

    const [email,setEmail] = useState('');
    const [code,setCode] = useState('');

    const [password,setPassword] = useState('');
    const [errorPassword,setErrorPassword] = useState(false);
    const [passwordConfirm,setPasswordConfirm] = useState('');
    const [errorPasswordConfirm,setErrorPasswordConfirm] = useState(false);

    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState('');
    const [msg,setMsg] = useState('');
    const [action,setAction] = useState('Aceptar')

    var success = false;

    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }


    const requestCode = async () => {
        setIsLoading(true)
        
        let response = await requestCodeToResetPassword(email);
        
        if(response.success === true)
        {
            setStep(1);
        }
        else
        {
            setTitle('Operacion Fallida!');
            setMsg(response.message);
            setOpen(true);
        }

        setIsLoading(false);
    }

    const verifyCode = async () => {
        setIsLoading(true)
        let response = await verifyRequestedCode(email,code);
        if(response.success === true)
        {
            setStep(2);
        }
        else
        {
            setTitle('Operacion Fallida!');
            setMsg(response.message);
            setOpen(true);
        }

        setIsLoading(false);
    }

    const formVerification = () => {
        var ban = 0;

        if(password === '')
        {
            setErrorPassword(true);
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setErrorPasswordConfirm(true);
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setErrorPasswordConfirm(true);
            ban = 1;
        }
        
        return ban;
    }

    const resetPassword = async () => {
        console.log('Password = ',passwordConfirm);
        console.log('PasswordConfirm = ',passwordConfirm);
        if(formVerification() === 0)
        {
            setIsLoading(true);
            let response = await changePassword(email,password,code);
            if(response.success === true)
            {
                setTitle('Operacion Exitosa!');
                setMsg(response.message);
                setOpen(true);
            }
            else
            {
                setTitle('Operacion Fallida!');
                setMsg(response.message);
                setOpen(true);
            }
        }
    }

    const handleAction = () => {
        if(step === 0)
        {
            if(title === 'Operacion Fallida!')
            {
                setIsLoading(false);
            }
            setOpen(false);
        }
        else if(step === 1)
        {
            if(title === 'Operacion Fallida!')
            {
                setIsLoading(false);
            }
            setOpen(false);
        }
        else
        {
            if(title === 'Operacion Fallida!')
            {
                setOpen(false);
                setIsLoading(false);
            }
            
            if(title === 'Operacion Exitosa!')
            {
                setOpen(false);
                setIsLoading(false);
                redirect('/auth/signIn');
            }
        }
    }
    

    return(
        <Box
            sx={{
                height:'100vh',
                display:'flex',
                flexDirectio:'row',
                bgcolor:'background.default'
            }}
        >
            <Grid container style={{display:'flex', flexDirection:'row',justifyContent:'center'}}>
                <Grid item xs={12} sm={8} md={8} lg={6} xl={6} style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',position:'relative'}}>
                    
                    <Grid container spacing={3} style={{justifyContent:'center'}}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Box
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '100%',borderColor:'#FFF' },
                                    '& .MuiLoadingButton-root':{m:1},
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    marginBottom:3,
                                    p:3
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                {
                                    step === 0 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Recuperar Contraseña
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            Para recuperar la contraseña ingresa tu correo electrónico  y te enviaremos
                                            un código de recuperación
                                        </Typography>
                                        <TextField
                                            color='primary'
                                            id="email"
                                            label="Email"
                                            name='email'
                                            variant="outlined"
                                            value={email}
                                            onChange={(value) => setEmail(value.target.value)}
                                        />
                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                            //disabled={isLoading}
                                            onClick={()=>requestCode()}
                                        >
                                            Solicitar código
                                        </LoadingButton>
                                    </>
                                }

                                {
                                    step === 1 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Código de Verificación
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            Ingresa el código de verificación que has recibido por correo electrónico
                                        </Typography>
                                        
                                        <Box sx={{color:'text.primary',mb:4}}>
                                            <ReactInputVerificationCode
                                                length={4}
                                                onChange = {(e)=>setCode(e)}
                                            />
                                        </Box>

                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                            onClick={()=>verifyCode()}
                                        >
                                            Verificar Código
                                        </LoadingButton>
                                    </>
                                }


                                {
                                    step === 2 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Recuperar Contraseña
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            A continuación ingresa la nueva contraseña que deseas usar para iniciar sesión
                                        </Typography>
                                        <TextField
                                            color='primary'
                                            variant="outlined"
                                            label="Nueva contraseña"
                                            id="password"
                                            type='password'
                                            name="password"
                                            value={password}
                                            onChange={(e)=>setPassword(e.target.value)}
                                            onBlur={ () => (password === '' ? setErrorPassword(true) : setErrorPassword(false) ) }
                                            error = {errorPassword}
                                            helperText={errorPassword && 'Ingrese la contraseña'}
                                        />

                                        <TextField
                                            color='primary'
                                            variant="outlined"
                                            label="Nueva contraseña"
                                            id="passwordConfirm"
                                            type='password'
                                            name="passwordConfirm"
                                            value={passwordConfirm}
                                            onChange={(e)=>setPasswordConfirm(e.target.value)}
                                            onBlur={ () => (passwordConfirm === '' ? setErrorPasswordConfirm(true) : setErrorPasswordConfirm(false) ) }
                                            error = {errorPasswordConfirm}
                                            helperText={errorPasswordConfirm && 'La contraseña no coincide'}
                                        />
                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                            onClick={()=>resetPassword()}
                                        >
                                            Restablecer Contraseña
                                        </LoadingButton>
                                    </>
                                }
                                
                            </Box>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>

            <ModalDialog
                open={open}
                handleClose={()=>handleAction()}
                title={title}
                msg={msg}
                handleAction={()=>handleAction()}
                action={action}
            />
        </Box>
    )
}